import React, {useState, createContext, useEffect, useContext} from 'react'
import { navigate } from 'gatsby'

// Available languages for this Website
const languages = [
  'en_US', 'es_ES', 'pl_PL'
]

// Language Keys for reference
const languageShorts = {
  'en_US' : 'en',
  'es_ES' : 'es',
  'pl_PL' : 'pl',
  'fr_FR' : 'fr',
  'nl_NL' : 'nl',
  'de_DE' : 'de',
  'it_IT' : 'it',
  'pt_BR' : 'pt',
  'ru_RU' : 'ru',
  'sv_SE' : 'sv',
}

// Base language of the site
const GATSBY_BASE_LANGUAGE = process.env.GATSBY_BASE_LANGUAGE

// Helper function
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

// Find out the url language, if matches available languages for this website, then pick it. Otherwise find out browser language, if matches available languages for this website, then pick that, otherwise return BASE LANGUAGE
const getBrowserLanguage = () => {
  if (typeof window === 'undefined') {
    return null
  }

  // Find out browser set language
  const first = window.navigator.languages
    ? window.navigator.languages[0]
    : null

  const browserLang = first ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage

  const browserLangFinal = browserLang.replace(/-/g, '_')

  // Find out URL language
  const urlLangKey = window.location.pathname.split('/')[1]
  const urlLangKeyFinal = urlLangKey && getKeyByValue(languageShorts,urlLangKey) ? getKeyByValue(languageShorts,urlLangKey) : GATSBY_BASE_LANGUAGE

  // Find out localstorage language from previous session or user manual choice
  const localLanguageFinal = localStorage.getItem('language') !== 'undefined' && typeof localStorage.getItem('language') !== 'undefined' ? JSON.parse(localStorage.getItem('language')) : null

  const language = urlLangKeyFinal !== GATSBY_BASE_LANGUAGE ? urlLangKeyFinal : // if the url has key, pick that
                   localLanguageFinal ? localLanguageFinal : // if there was manual choice and we got it saved, pick that
                   browserLangFinal !== GATSBY_BASE_LANGUAGE && languages.includes(browserLangFinal) ? browserLangFinal : // if browser language is supported, pick that
                   GATSBY_BASE_LANGUAGE // otherwise default to BASE LANGUAGE

  // console.log('URL Langguage:',urlLangKeyFinal)
  // console.log('Local Langguage:',localLanguageFinal)
  // console.log('Browser Language:',browserLangFinal)
  // console.log('Final Language:',language,urlLangKeyFinal !== GATSBY_BASE_LANGUAGE ? true : false,localLanguageFinal ? true : false,browserLangFinal !== GATSBY_BASE_LANGUAGE && browserLangFinal in languages ? true : false)

  return language
}

// CONTEXT
function LanguageContextProvider({children, location}) {

  
  // State for active language
	const [language, setLanguage] = useState()


  useEffect(() => {
    // Kill off redirect duh
    //redirect(getBrowserLanguage())
  },[])

  function redirect(language) {
    if (typeof window === 'undefined') {
      return null
    }

    setLanguage(language)

    // Find out URL language
    const pathname = window.location.pathname
    const pathnameFragmented = pathname.split('/')
    const urlLangKey = pathnameFragmented[1] // urllangvalue
    //const urlLangKeyFinal = urlLangKey && getKeyByValue(languageShorts,urlLangKey) ? getKeyByValue(languageShorts,urlLangKey) : GATSBY_BASE_LANGUAGE

    // If the first fragment of url is recognized as a language
    if (urlLangKey && getKeyByValue(languageShorts,urlLangKey)) {
      pathnameFragmented.splice(1,1)
    }

    // Is this the base language? (no prefix)
    const isBase = language === GATSBY_BASE_LANGUAGE ? true : false

    // Use gatsby navigate to swtich the url
    navigate(`${!isBase ? '/' + languageShorts[language] + pathnameFragmented.join('/') : pathnameFragmented.join('/')}`)
  }


  useEffect(() => {
    localStorage.setItem('language', JSON.stringify(language))
  }, [language])


	return (
		<LanguageContext.Provider value={{
      language,
      languages,
      languageShorts,
      languageShort: languageShorts[language],
      isBase: language === GATSBY_BASE_LANGUAGE ? true : false,
			switchLanguage: (value) => {redirect(value);},
    }}>
      {children}
    </LanguageContext.Provider>
	)
}


const LanguageContext = createContext(null)
export { LanguageContext }

const useLanguageContext = () => useContext(LanguageContext)
export { LanguageContextProvider, useLanguageContext }