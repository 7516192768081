import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'

import Hamburger from './Hamburger'
import NaviPrimary from './Navi/Primary'
import { HeaderTop } from './Top'
import SocialLinks from '../SocialLinks'
import LanguageSwitcher from '../LanguageSwitcher'

function Header(props) {
	const naviContext = useContext(NaviContext)

	// Animating fade in/out
	const baseDuration = 250
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item, .social-icons a')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				duration: baseDuration,
				easing: 'easeInOutSine',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateX: [-22, 0],
				duration: baseDuration,
				easing: 'easeInOutSine',
				delay: anime.stagger(50)
			}, `-=${baseDuration}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

	// Fire up expanded navi on hamburger activation alone
	const naviExpanded = naviContext.isHamburgerActive

  return (
		<header className={'master-header'}>
			<HeaderTop />
			<div className='master-second-row'>
				<div className="leftside">
					<Hamburger baseDuration={baseDuration} />

					{/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
					naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
					{/* {naviContext.windowSize?.mobile} */}
					<Transition
						in={naviExpanded}
						timeout={baseDuration}
						appear={true}
						onEntering={fadeInLogo}
						onExiting={fadeOutLogo}
						mountOnEnter
						unmountOnExit
					>
						<div className={`master-navi x1`}>
							<NaviPrimary { ...props } baseDuration={baseDuration} />
							{/* <SocialLinks classes="x1"/> */}
							<span className="close" 		
								onClick={() => {
										naviContext.activeToggle(false)
										naviContext.hamburgerActiveToggle(false)
									}
								}
								role="button"
								aria-label="Close navi pane"
								tabIndex={-33}
								onKeyDown={() => {
										naviContext.activeToggle(false)
										naviContext.hamburgerActiveToggle(false)
									}
								} 
							/>
						</div>
					</Transition>
				</div>
				<LanguageSwitcher />
				<SocialLinks classes="grey"/>
			</div>
		</header>
  )
}

export default Header