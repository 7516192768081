import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './styledump.scss'


function Insert(props) {

	// Get navi and copy from options
	const data = useStaticQuery(graphql`
		query {
			sanitySiteSettings {
				css {
					code
				}
				js {
					code
				}
			}
		}
	`)

	const css = data?.sanitySiteSettings?.css?.code
	const js = data?.sanitySiteSettings?.js?.code

	
	useEffect(() => {
		if (js) {
			eval(js)
		}
	}, [js])

	// useEffect(() => {
	// 	const script = document.createElement('script');

	// 	script.src = ""
	// 	script.async = true

	// 	document.body.appendChild(script)

	// 	script.onload = function() {
	// 		if (typeof window !== 'undefined') {
	// 			window.kWidget?.embed({
	// 				"targetId": "",
	// 				"wid": "",
	// 				"uiconf_id": ,
	// 				"flashvars": {
	// 				"playlistAPI.kpl0Id": ""
	// 			},
	// 				"cache_st": 
	// 			})
	// 		}
	// 	}

	// 	return () => {
	// 		document.body.removeChild(script)
	// 	}
	// }, [])


  return css ? (
		<>
			<style
				dangerouslySetInnerHTML={{ __html: css }}
			/>
		</>
  ) : false
}

export default Insert
