import React from "react"
import "./src/html.scss"
import Layout from "./src/components/layout"

import { LanguageContextProvider } from "./src/context/LanguageContext"
import { NaviContextProvider } from "./src/context/NaviContext"
import { ModalContextProvider } from "./src/context/ModalContext"
import { FSLightBoxContextProvider } from "./src/context/FSLightBoxContext"
import { ReuseableContextProvider } from "./src/context/ReuseableContext"

import { ParallaxProvider } from 'react-scroll-parallax'
import client from './src/apollo/client'
import {ApolloProvider} from '@apollo/client'

//import { SplitText, TimelineMax } from "gsap/all"

// Splitting text lines for animation
//import Splitting from "splitting"

//const transitionDelay = 200;

// Contexts
// export const wrapPageElement = ({ element }) => (
//   <NaviContextProvider>{element}</NaviContextProvider>
// )



// Layout
export const wrapPageElement = ({ element, props }) => {

  return (
    <LanguageContextProvider {...props}>
      <ParallaxProvider>
        <ApolloProvider client={client}>
          <FSLightBoxContextProvider {...props}>
            <ReuseableContextProvider {...props}>
              <NaviContextProvider {...props}>
                <ModalContextProvider {...props}>
                  <Layout {...props}>{element}</Layout>
                </ModalContextProvider>
              </NaviContextProvider>
            </ReuseableContextProvider>
          </FSLightBoxContextProvider>
        </ApolloProvider>
      </ParallaxProvider>
    </LanguageContextProvider>
  )
}

// export const onServiceWorkerUpdateReady = () => {
//   window.location.reload(true)
// }

// Splitting
export const onRouteUpdate = () => {

  // Smooth scroll to hash after route change
  if(window.location.hash) {
		const href = typeof window !== 'undefined' ? window.location.hash.substr(1) : ''
		const hash = href.split('?')[0].split('=')[0]
    window.setTimeout(() => window.scrollTo(0, 0), 0)
    if(document.querySelectorAll('#' + hash).length > 0) {
      const mimic = document.getElementById('master-header-mimic')
      window.setTimeout(() =>  window.scrollTo({
        top: window.pageYOffset - mimic?.offsetHeight + document.querySelector('#' + hash)?.getBoundingClientRect().top,
        behavior: 'smooth'
      }), 600)
    }
  }

  // // Elements that are direct children of splittext-lines class
  // const lines = document.querySelectorAll('.splittext-lines > h1, .splittext-lines > h2, .splittext-lines > h3, .splittext-lines > h4, .splittext-lines > h5, .splittext-lines > h6')
  // const chars = document.querySelectorAll('.splittext-chars > h1, .splittext-chars > h2, .splittext-chars > h3, .splittext-chars > h4, .splittext-chars > h5, .splittext-chars > h6')
  
  // Splitting({
  //   target: lines,
  //   by: 'lines',
  //   key: null
  // })

  // Splitting({
  //   target: chars,
  //   by: 'chars',
  //   key: null
  // })
  
  // // Wrap them once as per normal
  // const linesSplittext = new SplitText(lines, {
  //   type: "lines",
  //   linesClass: "text-line"
  // })
  // let linesToAnimate = linesSplittext.lines

  // // Wrap them again to prep for animation (overflow hidden)
  // const linesSplittextOverflow = new SplitText(lines, {
  //   type: "lines",
  //   linesClass: "muhlines line-++"
  // })


  // const isInview = document.querySelectorAll('.is-inview')
  // const nodes = [...isInview]

  // // Set threshold for
  // const config = {
  //   threshold: 0 // 0% of the element is visible
  // }

  // // // Start GSAP timeline
  // // const tl = new TimelineMax()
  
  // // Set up observer
  // let observer = new IntersectionObserver(function(entries, self) {
  //   entries.forEach(entry => {
  //     if (entry.isIntersecting) {
  //       entry.target.classList.add('inview','inview-rn')
  //     } else {
  //       entry.target.classList.remove('inview-rn')
  //     }
  //   })
  // }, config)
  
  // // Set up observers on all of the items
  // nodes.forEach(box => {
  //   observer.observe(box)
  // })

}

export const onInitialClientRender = () => {
  // Remove Loader
  document.getElementById("___loader").style.display = "none"

  // // Set flag and interval function
  // let interval
  // let flag = true

  // function forceRefresh(hours, minutes, seconds) {
  //   // Current time check
  //   if (typeof window !== 'undefined') {
  //     var now = new Date()
  //     var then = new Date()
  //     then.setHours(hours)
  //     then.setMinutes(minutes)
  //     then.setSeconds(seconds)
  //     console.log('bump',now,then)
  //     if ((now > then) && flag) {
  //       flag = false
  //       console.log('fireeeeee')
  //       window.location.reload(true)
  //     }
  //   }
  // }
  // interval = setInterval(() => forceRefresh(12,40,0), 10000)

}

// export const onClientEntry = () => {
//   window.onload = () => {
//     setTimeout(function() {
//       document.getElementById("___loader").style.display = "none"
//     }, 20)
//   }
// }

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition
// }) => {
//   if (location.action === "PUSH") {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     )
//   }
//   return false;
// }