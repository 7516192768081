import React, {useContext} from 'react'
import '../EVG/css/_core.scss';

import Header from './Header'

import { Helmet } from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'

import FsLightbox from 'fslightbox-react'
import FSLightBoxContext from '../context/FSLightBoxContext'
import Footer from './Footer';
import Insert from './Inserts';


function Layout(props) {
	const lightBoxContext = useContext(FSLightBoxContext)
	const headlessFooterless = props?.pageResources?.page?.path && (props?.pageResources?.page?.path !== '/') ? true : false

  return (
  	<>

			{/* <Helmet>
				<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
			</Helmet> */}

			{/* { headlessFooterless ?
				<Header {...props.data} />
			: null } */}
			
			<ParallaxProvider>
				{props.children}
			</ParallaxProvider>

			{lightBoxContext.sources ?
				<FsLightbox
					toggler={lightBoxContext.toggler}
					sources={lightBoxContext.sources}
					slide={lightBoxContext.slide}
					key={lightBoxContext.sources[0]}
					type={lightBoxContext.type}
				/>
			: null }

			{/* {headlessFooterless ?
				<Footer {...props.data} />
			: null } */}

			<Insert />

  	</>
  )
}

export default Layout