import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './style.scss'
import { StaticImage } from 'gatsby-plugin-image'

import Intro from '../../animations/Intro'

import { useInView } from 'react-intersection-observer'
import SocialLinks from '../SocialLinks'

function Footer(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  // Get navi and copy from options
  const data = useStaticQuery(graphql`
		query {
			sanitySiteSettings {
				copyrights
			}
		}
	`)

  const d = new Date()
  const year = d.getFullYear()
  const copy = data?.sanitySiteSettings?.copyrights
  //const css = data?.sanitySiteSettings?.css?.code


  return (
    <footer ref={io} className='master-footer fs-125 c5 padd flex-12'>
      <div className='span-6 span-12-mobile padd-top'>

        <Intro visible={ioInView} in={{ fadeRight: 700 }} delayIn={400} mounted={true} stay={true} className="overflow-visible stay-connected-wrap uppercase padd-bottom">
          <h2 className='h6 stay-connected'>
            © Copyright {year} <br />
            Irie Weekend<br />
            All Rights Reserved.<br />
          </h2>
        </Intro>

        <Intro visible={ioInView} in={{ fadeRight: 700 }} delayIn={400} mounted={true} stay={true} className="overflow-visible stay-connected-wrap uppercase">
          <h2 className='h6 stay-connected'>Stay Connected</h2>
        </Intro>

        <SocialLinks classes="uppercase padd-bottom" />

        <Intro visible={ioInView} in={{ fadeRight: 700 }} delayIn={650} mounted={true} stay={true} className="overflow-visible stay-connected-wrap uppercase">
          <h2 className='h6 stay-connected'>Site By <a target="_blank" rel="noreferrer noopener" href="https://www.deepsleepstudio.com/">Deepsleep Studio</a></h2>
        </Intro>

      </div>

      <div className='span-6 span-12-mobile flex jc-flex-end'>

        <Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={500} mounted={true} stay={true} className="logo">
          <div className="aspect-ratio">
            <StaticImage
              src={'../../../static/images/logo-white.png'}
              layout="fixed"
              //aspectRatio={1.56}
              objectFit="contain"
              objectPosition={'100% 50%'}
              placeholder='NONE'
              alt="Irie Weekend Logo"
            />
          </div>
        </Intro>

      </div>
    </footer>
  )
}

export default Footer
